import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const aboutBps = css(
  mq({
    marginTop: [34.5, 35, 32, 52]
  })
)

export const bgBps = css(
  mq({
    backgroundRepeat: ['no-repeat'],
    height: ['auto'],
    backgroundSize: ['cover'],
    backgroundPosition: ['70% 0%', '70% 0%', 'right', 'right']
  })
)

export const titleBps = css(
  mq({
    width: ['100%', '100%', 620, 620],
    fontSize: [33.6, 33.6, 47.9, 47.9],
    textAlign: ['center', 'center', 'left', 'left']
  })
)

export const subTitleBps = css(
  mq({
    width: ['100%', '100%', 620, 620],
    textAlign: ['center', 'center', 'left', 'left']
  })
)

export const textBps = css(
  mq({
    width: ['100%', '100%', 620, 620],
    textAlign: ['center', 'center', 'left', 'left']
  })
)

export const title = css`
  ${titleBps};
  font-size: 70px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 50px;
  span {
    background-color: #f8e71c;
    color: #9013fe;
    padding: 0 4px;
    font-weight: bold;
  }
`

export const subTitle = css`
  ${subTitleBps};
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 28px;
`
export const itemContent = css`  
    font-weight: 600;
`

export const lineThrough = css`

    text-decoration: line-through;
    text-decoration-color: #ff3b30;
`

export const valueContent = css`
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #212121;

`

export const radioOffer = css`
  margin-left: 16px;
  margin-bottom: 14px;
`
export const footer = css`
  margin-top: 10px;
  color: '#44474e';
`
export const currency = css`
  font-size: 13.8px;
  font-weight: 600;
  line-height: 1.74;
  letter-spacing: 0.1px;
  text-align: center;
`

export const text = css`
  display: block;
  ${textBps};
  margin-bottom: 8px;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;

  span {
    background-color: #f8e71c;
    color: #9013fe;
    padding: 0 5px;
    font-weight: bold;
  }
`