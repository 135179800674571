import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import MaskedInput from 'react-text-mask';

import Caption from './Caption'

const fieldStyles = makeStyles({
  root: {
    '& legend': {
      position: 'relative',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#fff',
      color: '#fff'
    },
  },
  input: {
    borderRadius: 30,
    paddingLeft: '19px!important',
    backgroundColor: '#fff',
    border: 'solid 1px #fff',
    '&:placeholder': {
      fontSize: 15.7
    },
    '&.Mui-focused': {
      border: 'solid 1px #fff'
    },
    '& textarea:placeholder': {
      color: '#fff'
    },
    '&.blurred': {
      border: 'solid 1px rgba(0, 0, 0, #f9fafa)'
    },
    '&.opaque': {
      backgroundColor: '#fff',
      border: 'solid 1px #fff'
    },
  },
  animatedLabel: {
    left: 11,
    top: -5,
    fontSize: 15.7,
    color: 'rgba(68, 71, 78, 0.5)',
    fontWeight: 'bold',
    '&.Mui-focused': {
      transform: 'translate(13px, -3px) scale(0.75)',
      color: '#fff'
    },
    '&.MuiInputLabel-shrink': {
      transform: 'translate(13px, -3px) scale(0.75)',
      color: 'transparent'
    },
  },
  adornedEnd: {
    paddingRight: 0,
    '& .MuiInputAdornment-root': {
      position: 'absolute',
      right: 10,
    },
    '&.customAdornment .MuiInputAdornment-root': {
      position: 'absolute',
      right: 16,
      top: 29,
    },
    '&.customAdornment .MuiButtonBase-root': {
      padding: 8,
    },
  },
});

function TextMaskCustom(props) {
  const { inputRef, mask, pipe, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      pipe={pipe}
    />
  );
}

const MuiField = ({
  field,
  InputProps,
  inputProps,
  customAdornment,
  multiline,
  opaque,
  caption,
  style,
  mask,
  pipe,
  onChange,
  value,
  ...rest
}) => {
  const classes = fieldStyles();
  const [internalValue, setValue] = useState(value || '')
  return (
    <div
      style={style}
    >
      <TextField
        {...field}
        value={internalValue}
        InputLabelProps={{
          classes: {
            animated: classes.animatedLabel,
          },
        }}
        style={{
          width: '100%',
          marginBottom: 0
        }}
        multiline={multiline}
        inputProps={{
          style: {
            padding: !multiline && InputProps && InputProps.endAdornment ? '16px 55px 16px 28px' : '16px 28px 16px 28px',
            fontFamily: 'Montserrat, sans-serif',
            fontSize: 15.7,
            lineHeight: 1.53,
            letterSpacing: '0.15px',
            fontWeight: 600,
            color: '#44474e',
            zIndex: 999,
            ...inputProps.style
          },
          onChange: (e) => {
            if (onChange) {
              onChange(e)
            }
            setValue(e.target.value)
          },
          mask,
          pipe
        }}
        InputProps={{
          ...InputProps,
          classes: {
            root: classes.root,
            notchedOutline: clsx(
              classes.input,
              opaque && 'opaque'
            ),
            adornedEnd: clsx(
              classes.adornedEnd,
              customAdornment && 'customAdornment',
            ),
          },
          inputComponent: mask && TextMaskCustom
        }}
        {...rest}
      />
      {caption && (
        <Caption
          color='white'
          selected
          on='onSurface'
          align='center'
          style={{
            padding: '0 28px'
          }}
        >
          {caption}
        </Caption>
      )}
    </div>
  );
};

MuiField.defaultProps = {
  inputProps: {
    style: {}
  }
}

export default MuiField;
