import React, { Fragment } from 'react'
import { Link }  from 'gatsby'
import ds from '../theme'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Column from '../components/Column'
import Div from './../components/Div'
import Row from '../components/Row'
import H4 from '../components/H4'
import H5 from '../components/H5'
import Subtitle from '../components/SubTitle2'
import Paragraph from '../components/Paragraph'
import StorageForm from '../components/OfertasForm'
import { Container, mq } from '../components/styles/Layout.styles'
import { css } from '@emotion/core'


import BgOferta from '../images/oferta/bg.png'
import {
  bgBps, currency, footer, itemContent, lineThrough, subTitle, text, title
} from '../components/styles/oferta.styles'
import { 
  termosBoxWhite 
} from '../components/styles/Contact.styles'

import { validateRequiredEmail } from '../utils/validations'

import { item } from '../components/styles/DedicatedServersPrice.styles'
import H6 from '../components/H6'

const fields = [
  {
    label: 'Nome',
    name: 'your-name', 
    type: 'text',
    required: true
  },
  {
    label: 'Email',
    name: 'your-email',
    type: 'email',
    required: true,
    validate: validateRequiredEmail,
  },
  {
    label: 'Telefone',
    name: 'your-phone',
    type: 'text',
    required: true,
    mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  },
  {
    label: 'Empresa',
    name: 'your-company',
    type: 'text',
    required: true
  },
  {
    type: 'radio',
    name: 'offer',
    required: true,
    content: [
      {
        label: 'Oferta 1 - R$497,00',        
        type: 'radio',
        value: 'Oferta 1 - R$497,00',
        required: true
      },
      {
        label: 'Oferta 2 - R$798,00',
        type: 'radio',
        value: 'Oferta 2 - R$798,00',
        required: true
      },
      {
        label: 'Oferta 3 - Consultoria grátis',
        type: 'radio',
        value: 'Oferta 3 - Consultoria grátis',
        required: true
      }
    ]
  },
  {
    label: (
      <span css={termosBoxWhite}>
        Ao continuar, indica que aceita os
        {" "}
        <Link to="/termos-de-servico" style={{ textDecoration: 'underline' }}>
          Termos de Serviço
        </Link>
        {" "}
        e a nossa
        {" "}
        <Link to="/politica-de-privacidade" style={{ textDecoration: 'underline' }}>
          Política de Privacidade.
        </Link>
      </span>
    ),
    name: 'your-consent',
    type: 'checkbox',
    required: true
  },
]

const ProductItem = ({
  productName,
  productAdvantages,
  activationText,
  productDescription,
  oldPrice,
  productPrice,
  bgColor,
  fontColor,
  content
}) => {
  return (
    <div
      style={{
        height: 620,
        padding: '24px 16px 40px 16px',
        backgroundColor: bgColor,
        border: 'solid 1px rgba(0, 0, 0, 0.12)',
        borderRadius: 12,
        color: fontColor
      }}
      css={
        [css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        ),
        item
      ]
      }
    >
      <Div
        flexDirection='column'
        alignItems='center'
        
      >
        <H5
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            letterSpacing: 0.25,
            width: 296,
            textAlign: 'center',
            marginTop: 76,
            color: fontColor,
            fontSize: 24,
            fontWeight: 600
          }}
        >
          {productName}
        </H5>
        <Subtitle
          style={{
            fontSize: 18,
            fontWeight: 600,
            lineHeight: 1.4,
            letterSpacing: 0.1,
            textAlign: 'center',
            color: fontColor,
            width: '90%',
            whiteSpace: 'pre-wrap',
            marginTop: 8
          }}
        >
          {productAdvantages}
        </Subtitle>

        { activationText && (
          <H6
            on='onSurface'
            variant='highEmphasis'
            style={{
              letterSpacing: 0.25,
              width: 296,
              textAlign: 'center',
              marginTop: 20,
              color: fontColor,
              fontSize: 15,
              fontWeight: 600
            }}
          >
            {activationText}
          </H6> 
        ) }

        { !productDescription 
          ? <>
            <Div
              alignItems="center"
            >
              <Row mt={[28]}>
                <div>
                  <span css={itemContent}>Data Center:</span>{content.datacenter}
                </div>
              </Row>

              <Row 
                mt={[10]} 
                mx={2}
                
              >
                <div>
                  <span css={itemContent}>CPU:</span>{content.cpu}
                </div>
              </Row>

              <Row mt={[10]}>
                <div>
                  <span css={itemContent}>RAM:</span>{content.ram}
                </div>            
              </Row>

              <Row mt={[10]}>
                <div>
                  <span css={itemContent}>DISCO:</span>{content.hd}
                </div>            
              </Row>

              <Row 
                mt={[8]}
              >
                <div>
                  <span css={itemContent}>BANDA:</span>{content.bandwidth}
                </div>
              </Row>

              <Row mt={[10]}>
                <div>
                  <span css={itemContent}>OS:</span>{content.os}
                </div>            
              </Row>

              <Row mt={[10]} mb={[30]}>
                <div>
                  <span css={itemContent}>LINK:</span>{content.link}
                </div>            
              </Row>
            </Div>

            <H4
              selected
              on='onSurface'
              variant='highEmphasis'
              style={{
                letterSpacing: 0.25,
                marginBottom: 8,
                marginTop: 25,
                display: 'flex',
                alignItems: 'center',
                color: fontColor
              }}
            >
              <span css={currency}>R$</span>
              <span css={lineThrough}>{oldPrice}</span>
              <span css={currency}>/mês</span>
            </H4>

            <H4
              selected
              on='onSurface'
              variant='highEmphasis'
              style={{
                letterSpacing: 0.25,
                marginBottom: 8,
                display: 'flex',
                alignItems: 'center',
                color: fontColor
              }}
            >
              <span css={currency}>R$</span>
              {productPrice}
              <span css={currency}>/mês</span>
            </H4>
            
            <p css={footer}>Poucas unidades disponíveis</p>
          </>
          
          : <Paragraph
            style={{
              marginTop: 30,
              textAlign: 'center',
              fontSize: 16,
              fontWeight: 600
            }}
            >
            {productDescription}
          </Paragraph> }
        
        
      </Div>
    </div>
  )
}

export const PageTemplate = ({
  data
}) => {

  return (
    <Fragment>
      <section>
        <div
          css={bgBps} 
          style={{ 
            backgroundImage: `url(${BgOferta})`
          }}
        >
          <Div
            maxWidth={['100%', 1040]}
            style={{
              margin: 'auto'
            }}
          >
            <Row
              flexDirection={['column', 'column', 'row', 'row']}
              mt={[50, 105]}
              pb={[50, 100]}
              mx={[0, 0, -24, -24]}
            >
              <Column
                width={[1]}
              >
                <Div
                  css={title}
                >
                  Seu servidor dedicado no melhor Data Center do Brasil
                </Div>
                <Div
                  css={subTitle}
                >
                  Muito além do bare metal, com a HostDime Brasil, você garante excelência para a sua operação e um suporte especializado, 24 x 7.
                </Div>

                <Div 
                  css={text}
                >                  
                  Você está a um clique do melhor investimento para o seu negócio, ofertas imperdíveis num pacote com <br /><span>3 meses de gerenciamento avançado grátis.</span>
                </Div>
              </Column>
                
              <Column
                width={[1]}
                
              >
                <Div
                  alignItems={['center', 'center', 'flex-start', 'flex-start']}
                  style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <StorageForm
                    fields={fields}
                    formId={4531}
                    submitText='Fale com um consultor'
                  />
                </Div>
              </Column>
            </Row>
          </Div>
        </div>
      </section>

      <section>
        <Container>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'column', 'row']}
            alignItems={'center'}
            mt={30}
          >
            <Column
              mb={[16, 24]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <ProductItem
                  productName='OFERTA 01'
                  productAdvantages="Servidor Dedicado"
                  activationText="Ativação em até 24 horas"
                  oldPrice='699,00'
                  productPrice='497,00'
                  bgColor="#f3f5f5"
                  fontColor="#44474e"
                  content={{
                    datacenter:" São Paulo",
                    cpu: " Single 4 Core Xeon E3",
                    ram: " 32GB",
                    hd: " 1x 240GB SSD",
                    bandwidth: " 20TB",
                    os: " CentOS",
                    link: " 1Gbps Uplink"
                  }}
                />
              </Div>
            </Column> 
            
            <Column
              mb={[16, 24]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <ProductItem
                  productName='OFERTA 02'
                  productAdvantages="Servidor Dedicado"
                  activationText="Ativação em até 24 horas"
                  oldPrice='990,00'
                  productPrice='798,00'
                  bgColor="#9013fe"
                  fontColor="#ffffff"
                  content={{
                    datacenter:" São Paulo",
                    cpu: " 2 x 8-Core Xeon",
                    ram: " 128GB",
                    hd: " 2x 600GB SAS - RAID 1",
                    bandwidth: " 20TB",
                    os: " CentOS",
                    link: " 1Gbps Uplink"
                  }}
                />
              </Div>
            </Column> 

            <Column
              mb={[16, 24]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <ProductItem
                  productName='OFERTA 03'
                  productAdvantages="1 HORA de consultoria com especialista"
                  productPrice='1.800,00'
                  productDescription={"Quer encontrar a melhor solução customizada para o seu negócio? Agende 1 hora de consultoria de um dos nossos executivos. Desenhamos soluções personalizadas para te oferecer tranquilidade e o melhor custo benefício."}
                  bgColor="#f3f5f5"
                  fontColor="#44474e"
                  content={{
                    datacenter:" São Paulo",
                    cpu: " Dual Octacore (16 cores, 32 threads)",
                    ram: " 32GB",
                    hd: " 2x1TB HDD Enterprise",
                    bandwidth: " 20TB"
                  }}
                />
              </Div>
            </Column> 
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({ data }) => {
  return (
    <Layout>
      <Seo
        title='Seu dedicado no melhor Data Center do Brasil'
        description='Ofertas imperdíveis com 3 meses de gerenciamento avançado grátis'
      />
      <PageTemplate
        title='Oferta'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export default StaticPage